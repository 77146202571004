import { AppConfig } from './AppConfig';
import { ThemeConfig } from './ThemeConfig';

export const AppMetadataConfig = {
  title: AppConfig.CONSTANTS.APP_NAME,
  description: 'MetaPals Admin',
  themeColor: ThemeConfig.color.themeColor,
  appUrl: AppConfig.ENV.APP_URL,
  twitterCreator: '@MetaPals',
};
