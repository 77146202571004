interface IgnoredExceptions {
  object: any[];
  message: string[];
}

export const ignoredClientSideExceptions: IgnoredExceptions = {
  object: [],
  message: [],
};

export const ignoredServerSideExceptions: IgnoredExceptions = {
  object: [],
  message: [],
};

export const isSentryExceptionIgnoreable = (
  ignoredException: IgnoredExceptions,
  originalException: string | Error | null | undefined | unknown
) => {
  if (originalException instanceof Error) {
    for (const errorObj of ignoredException.object) {
      if (originalException instanceof errorObj) {
        return true;
      }
    }
    if (!originalException.message) {
      return true;
    }
    for (const errorMsg of ignoredException.message) {
      if (originalException.message.includes(errorMsg)) {
        return true;
      }
    }
  }
  return false;
};
