export enum LanguageTag {
  EN = 'en',
}

export interface LanguageData {
  tag: LanguageTag;
  label: string;
  isRTL: boolean;
}

export const languageList: Record<LanguageTag, LanguageData> = {
  [LanguageTag.EN]: {
    tag: LanguageTag.EN,
    label: 'English',
    isRTL: false,
  },
};
