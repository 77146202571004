var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"6f15b0db8c453735fe6bf3eab2cf834261b3375f"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { AppConfig } from '@/config';
import { initSentry } from '@/core/lib/sentry';

if (AppConfig.ENV.SENTRY.ENABLED) {
  initSentry();
}
