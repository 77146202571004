export const AppConfig = {
  ENV: {
    PRODUCTION:
      process.env.NEXT_PUBLIC_ENV === 'production' &&
      process.env.NODE_ENV === 'production',
    API_BASE_URL: process.env.NEXT_PUBLIC_API_BASE_URL,
    SENTRY: {
      ENABLED: process.env.NEXT_PUBLIC_SENTRY_ENABLED === 'true',
      RELEASE_ENABLED:
        process.env.NEXT_PUBLIC_SENTRY_RELEASE_ENABLED === 'true',
      DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
    },
    GA4_MEASUREMENT_ID: process.env.NEXT_PUBLIC_GA4_MEASUREMENT_ID,
    IS_SERVER: typeof window === 'undefined',
    APP_VERSION: process.env.NEXT_PUBLIC_APP_VERSION,
    APP_URL: process.env.NEXT_PUBLIC_APP_URL,
    API_KEY_FIREBASE: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
    AUTH_DOMAIN_FIREBASE: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  },
  CONSTANTS: {
    APP_NAME: 'MetaPals Admin',
    APP_NAME_KEY: 'MetaPals-Admin'.toLowerCase(),
    COOKIE: {
      TOKEN_PREFIX: 'token',
    },
  },
};
