export const NextApiRoute = {
  Token: '/api/token',
  Login: '/api/auth/login',
  Logout: '/api/auth/logout',
  LoginCallback: '/api/auth/callback',
  RefreshToken: '/api/auth/refresh',
  GetAuthUser: '/api/auth/user',
  GetAuthPermissions: '/api/auth/permissions',
};

export const NextPageRoute = {
  Dashboard: '/',
  Login: '/login',
  Settings: '/settings',
  CouponCode: '/coupon-code',
  Users: '/users',
  Tools: {
    KennelSkinEditor: '/tools/kennel-skin-editor',
    PetFrameworkDebugger: '/tools/pet-framework-debugger',
  },
  Mail: {
    Messages: '/messages',
    OwedDelivery: '/owed-delivery',
    Announcements: '/announcements',
  },
  Routines: {
    DailyRewards: '/routines/daily-rewards',
    LoginStreakRewards: '/routines/login-streak-rewards',
    MonthlyRewards: '/routines/monthly-rewards',
  },
};
