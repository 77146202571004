import storage from 'redux-persist/lib/storage';
import { ReduxStateKey } from '@/types';
import { PersistConfig } from 'redux-persist';
import { AppConfig } from './AppConfig';

interface ReduxReducerPersistConfig extends PersistConfig<any> {
  whitelist?: ReduxStateKey[];
}

export const ReduxPersistConfig: ReduxReducerPersistConfig = {
  key: `${AppConfig.CONSTANTS.APP_NAME_KEY}-root`,
  storage,
  whitelist: [ReduxStateKey.Auth, ReduxStateKey.AppSettings],
};
