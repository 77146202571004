export const ThemeConfig = {
  color: {
    themeColor: '#FFF',
  },
  breakpoints: {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1600,
  },
};
