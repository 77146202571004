export interface ApiStateWithData<T> {
  status: 'idle' | 'loading' | 'success' | 'failed';
  data: T | null;
  error: any;
}

export enum ReduxStateKey {
  Auth = 'auth',
  AppSettings = 'appSettings',
}
