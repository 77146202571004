import { AppConfig } from '@/config';
import * as Sentry from '@sentry/nextjs';
import {
  ignoredClientSideExceptions,
  isSentryExceptionIgnoreable,
} from './IgnoredSentryError';

export const initSentry = () => {
  const envName = !AppConfig.ENV.PRODUCTION ? 'development' : 'production';

  Sentry.init({
    dsn: AppConfig.ENV.SENTRY.DSN,
    integrations: [
      ...(!AppConfig.ENV.IS_SERVER ? [new Sentry.BrowserTracing()] : []),
    ],
    environment: envName,
    dist: envName,
    ...(AppConfig.ENV.SENTRY.RELEASE_ENABLED && {
      release: `${envName}@v${AppConfig.ENV.APP_VERSION}`,
    }),
    sampleRate: 1.0,
    tracesSampleRate: 1.0,
    autoSessionTracking: true,
    beforeSend(event, hint) {
      if (
        isSentryExceptionIgnoreable(
          ignoredClientSideExceptions,
          hint?.originalException
        )
      ) {
        return null;
      }
      return event;
    },
  });
};
